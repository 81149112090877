export interface ILanguage {
  country: string
  name: string
  originalName: string
  iconSrc: string
  iconRect?: string
  locale: string
  href?: string
}

const languages: ILanguage[] = [
  {
    originalName: 'Русский',
    name: 'Russian',
    country: 'Russia',
    iconSrc: '/images/flags/russia.svg',
    iconRect: '/images/flags/rect/russia.svg',
    locale: 'ru',
    href: 'https://binany.com/ru',
  },
  {
    country: 'Portugal',
    originalName: 'Português',
    name: 'Portuguese',
    iconSrc: '/images/flags/portugal.svg',
    locale: 'pt',
  },
  {
    originalName: 'Türkce',
    name: 'Turkish',
    country: 'Turkey',
    iconSrc: '/images/flags/turkey.svg',
    locale: 'tr',
  },
  {
    country: 'Germany',
    originalName: 'Deutsch',
    name: 'German',
    iconSrc: '/images/flags/germany.svg',
    locale: 'de',
  },
  {
    country: 'France',
    originalName: 'Français',
    name: 'French',
    iconSrc: '/images/flags/france.svg',
    locale: 'fr',
  },
  {
    country: 'Thailand',
    originalName: 'ไทย',
    name: 'Thai',
    iconSrc: '/images/flags/thailand.svg',
    locale: 'th',
  },
  {
    country: 'India',
    originalName: 'Hindi',
    name: 'Hindi',
    iconSrc: '/images/flags/india.svg',
    iconRect: '/images/flags/rect/india.svg',
    locale: 'hi',
    href: 'https://binany.com/hi',
  },
  {
    country: 'United Kingdom',
    originalName: 'English',
    name: 'English',
    iconSrc: '/images/flags/uk.svg',
    iconRect: '/images/flags/rect/uk.svg',
    locale: 'en',
    href: 'https://dev.bnn24dev.com/',
  },
  {
    country: 'United Kingdom',
    originalName: 'English(Bangladesh)',
    name: 'English',
    iconSrc: '/images/flags/uk.svg',
    iconRect: '/images/flags/rect/uk.svg',
    locale: 'en-BD',
    href: 'https://dev24.bnn24dev.com/en-BD',
  },
  {
    country: 'Spain',
    originalName: 'Español',
    name: 'Spanish',
    iconSrc: '/images/flags/spain.svg',
    locale: 'es',
  },
  {
    country: 'Indonesia',
    originalName: 'Indonesia',
    name: 'Indonesian',
    iconRect: '/images/flags/rect/indonesia.svg',
    iconSrc: '/images/flags/indonesia.svg',
    locale: 'id',
    href: 'https://binany.com/id',
  },
  {
    country: 'Italy',
    originalName: 'Italiano',
    name: 'Italian',
    iconSrc: '/images/flags/italy.svg',
    locale: 'it',
  },
  {
    country: 'South Korea',
    originalName: '한국어',
    name: 'Korean',
    iconSrc: '/images/flags/south-korea.svg',
    locale: 'ko',
  },
  {
    country: 'Sweden',
    originalName: 'Svenska',
    name: 'Sweden',
    iconSrc: '/images/flags/sweden.svg',
    locale: 'sv',
  },
  {
    country: 'Bangladesh',
    originalName: 'বাংলাদেশ (India)',
    name: 'Bengali',
    iconSrc: '/images/flags/bangladesh.svg',
    iconRect: '/images/flags/rect/bangladesh.svg',
    locale: 'bn',
    href: 'https://dev.bnn24dev.com/bn',
  },
  {
    country: 'Bangladesh',
    originalName: 'বাংলাদেশ (Bangladesh)',
    name: 'Bengali',
    iconSrc: '/images/flags/bangladesh.svg',
    iconRect: '/images/flags/rect/bangladesh.svg',
    locale: 'bn-BD',
    href: 'https://dev24.bnn24dev.com/',
  },
  {
    country: 'Malaysia',
    originalName: 'Malaysia',
    name: 'Malay',
    iconRect: '/images/flags/rect/malaysia.svg',
    iconSrc: '/images/flags/malaysia.svg',
    locale: 'ms',
    href: 'https://binany.com/ms',
  },
  {
    country: 'Pakistan',
    originalName: `پاکستان`,
    name: 'Pakistan',
    iconRect: '/images/flags/rect/pakistan.svg',
    iconSrc: '/images/flags/pakistan.svg',
    locale: 'ur',
    href: 'https://binany.com/ur',
  },
  {
    country: 'Uzbekistan',
    originalName: `O'zbek`,
    name: 'Uzbekistan',
    iconRect: '/images/flags/rect/uzbekistan.svg',
    iconSrc: '/images/flags/uzbekistan.svg',
    locale: 'uz',
    href: 'https://binany.uz',
  },
  {
    country: 'Myanmar',
    originalName: `Myanmar`,
    name: 'Myanmar',
    iconRect: '/images/flags/rect/myanmar.svg',
    iconSrc: '/images/flags/myanmar.svg',
    locale: 'my',
    href: 'https://binany.com/my',
  },
  {
    country: 'Ethiopia',
    originalName: `አማርኛ`,
    name: 'Amharic',
    iconRect: '/images/flags/rect/ethiopia.svg',
    iconSrc: '/images/flags/ethiopia.svg',
    locale: 'am',
    href: 'https://binany.com/am',
  },
  {
    country: 'Pakistan',
    originalName: `ਪੰਜਾਬੀ`,
    name: 'Punjabi',
    iconRect: '/images/flags/rect/pakistan.svg',
    iconSrc: '/images/flags/pakistan.svg',
    locale: 'pa',
    href: 'https://binany.com/pa',
  },
  {
    country: 'Sri Lanka',
    originalName: `සිංහල`,
    name: 'Sinhalese',
    iconRect: '/images/flags/rect/sri-lanka.svg',
    iconSrc: '/images/flags/sri-lanka.svg',
    locale: 'si',
    href: 'https://binany.com/si',
  },
  {
    country: 'Kenya',
    originalName: `Kiswahili`,
    name: 'Swahili',
    iconRect: '/images/flags/rect/kenya.svg',
    iconSrc: '/images/flags/kenya.svg',
    locale: 'sw',
    href: 'https://binany.com/sw',
  },
  {
    country: 'Vietnam',
    originalName: `Tiếng Việt`,
    name: 'Vietnamese',
    iconRect: '/images/flags/rect/vietnam.svg',
    iconSrc: '/images/flags/vietnam.svg',
    locale: 'vi',
    href: 'https://binany.com/vi',
  },
  {
    country: 'Nigeria',
    originalName: `Hausa`,
    name: 'Hausa',
    iconRect: '/images/flags/rect/nigeria.svg',
    iconSrc: '/images/flags/nigeria.svg',
    locale: 'ha',
    href: 'https://binany.com/ha',
  },
  {
    country: 'Saudi Arabia',
    originalName: `العربية`,
    name: 'Arabic',
    iconRect: '/images/flags/rect/saudi-arabia.svg',
    iconSrc: '/images/flags/saudi-arabia.svg',
    locale: 'ar',
    href: 'https://binany.com/ar',
  },
]

export const whiteList = [
  'en',
  'en-BD',
  'hi',
  'bn',
  'bn-BD',
  'ru',
  'uz',
  'id',
  'ms',
  'my',
  'am',
  'pa',
  'si',
  'sw',
  'vi',
  'ha',
  'ar',
]

export default languages
