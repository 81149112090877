import Cookies from 'js-cookie'

const setTokensAndRedirect = async (authToken, refreshToken) => {
  Cookies.set('auth.access', authToken)
  Cookies.set('auth.refresh', refreshToken, { expires: 30 })
  localStorage.removeItem('firstDepositBannerShown')
  if (Cookies.get('registrationSourceId')) {
    Cookies.remove('registrationSourceId')
  }
  window.location.assign(process.env.NEXT_PUBLIC_REACT_DASHBOARD_LINK)
}

export default setTokensAndRedirect
