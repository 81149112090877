import { useEffect } from 'react'

import { useRouter } from 'next/router'
import Cookies from 'js-cookie'

const LanguageProvider = ({ children }) => {
  const router = useRouter()
  const { pathname, asPath, query } = router

  const hostname = Cookies.get('hostname')

  useEffect(() => {
    if (navigator.language) {
      const domainLocales = router.domainLocales.find((domainLocale) => {
        return domainLocale.domain === hostname
      })
      console.log(hostname, 'hostname')
      console.log(router.domainLocales, 'router.domainLocales')

      console.log(domainLocales, 'domainLocales')

      const localeToRedirect = domainLocales?.locales
        .concat(domainLocales?.defaultLocale)
        .find((locale) => {
          return navigator.language === locale.substring(0, 2)
        })
      console.log(localeToRedirect, 'localeToRedirect')
      if (localeToRedirect && !localStorage.getItem(`selectedLang`)) {
        localStorage.setItem(`selectedLang`, localeToRedirect)
        router.push({ pathname, query }, asPath, {
          locale: localeToRedirect,
        })
      }
    }
  }, [])

  return children
}
export default LanguageProvider
