import cn from 'classnames'
/* eslint-disable @next/next/no-script-component-in-head */
import { PlausibleEvent, PopupType } from 'enums/plausible'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { whiteList as languagesWhitelist } from 'static/languages'
import useAmplitude from 'utils/hooks/useAmplitude'
import useDisablingZoom from 'utils/hooks/useDIsablingZoom'
import useFacebookSDK from 'utils/hooks/useFacebookSDK'
import usePlausible from 'utils/hooks/usePlausible'
import usePreviousRoute from 'utils/hooks/usePreviousRoute'
import useRedirectWithTokensFromQuery from 'utils/hooks/useRedirectWithTokensFromQuery'
import useTelegramMiniapp from 'utils/hooks/useTelegramMiniapp'
import useYandexMetrika from 'utils/hooks/useYandexMetrika'
import InfoProvider from 'utils/providers/InfoProvider/InfoProvider'

import { appWithTranslation, useTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Script from 'next/script'

import LanguageProvider from 'layouts/LanguageProvider'

import Popup from 'components/Popup/Popup'

import '../styles/globals.scss'
import 'react-toastify/dist/ReactToastify.css'
import styles from './_app.module.scss'

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const redirectToApp = useRedirectWithTokensFromQuery()
  const [t] = useTranslation(['shared', 'seo', 'main'])
  useYandexMetrika()

  useDisablingZoom()
  const { initData } = useTelegramMiniapp()
  const { initFacebookSdk } = useFacebookSDK()

  useEffect(() => {
    // Redirect to tg auth page when in tg webapp
    if (
      !!initData &&
      router.pathname !== '/auth/telegram' &&
      router.pathname !== '/regulation/agreement' &&
      router.pathname !== '/regulation/privacy'
    ) {
      router.push('/auth/telegram')
    }
  }, [initData, router])

  const { initAmplitude } = useAmplitude()

  const referrer = usePreviousRoute()
  // useCarrotQuest()

  const setEvent = usePlausible()
  useEffect(() => {
    initAmplitude()
    initFacebookSdk()

    setEvent(PlausibleEvent.OpenedBinany, { referrer })
  }, [])

  const hostname = Cookies.get('hostname')
  // Cookies.remove('hostname')
  const path = router.pathname
  useEffect(() => {
    const { click_id: clickId, offer, partner_id: partnerId, ref } = router.query
    if (clickId) {
      const id: string = Array.isArray(clickId) ? clickId.join('') : clickId
      Cookies.set('click_id', id, { expires: 45 })
    }
    if (partnerId) {
      const pid: string = Array.isArray(partnerId) ? partnerId.join('') : partnerId
      Cookies.set('partner_id', pid, { expires: 45 })
    }
    if (offer) {
      const pid: string = Array.isArray(offer) ? offer.join('') : offer
      Cookies.set('offer', pid, { expires: 45 })
    }
    if (ref) {
      const rf: string = Array.isArray(ref) ? ref.join('') : ref
      localStorage.setItem('_ref', rf)
    }
    if (clickId || partnerId || offer || ref) {
      router.replace(router.pathname)
    }
  }, [router])

  const [showScroll, setShowScroll] = useState(false)

  const cannonicalRef = useCallback(() => {
    const pathname = router.pathname.length > 1 ? router.pathname : ''
    return router.locale === router.defaultLocale
      ? `https://${hostname}${pathname}`
      : `https://${hostname}/${router.locale}${pathname}`
  }, [router])

  // scrollPopupOpen - стейт для открытия/закрытия попапа scrollPopupAlreadyOpened - стейт для понимания показывался ли вообще попап на этом роуте. аналогично для
  // timeUPPopupOpen и timeUPPopupAlreadyOpened
  const [scrollPopupOpen, setScrollPopupOpen] = useState(false)
  const [scrollPopupAlreadyOpened, setScrollPopupAlreadyOpened] = useState(false)
  const [timeUPPopupOpen, setTimeUPPopupOpen] = useState(false)
  const [timeUPPopupAlreadyOpened, setTimeUPPopupAlreadyOpened] = useState(false)

  const [timer, setTimer] = useState(0)
  const tick = useRef(null)

  // timeUPPopupAlreadyOpened и scrollPopupAlreadyOpened сбрасываются когда меняется роут
  useEffect(() => {
    setScrollPopupAlreadyOpened(false)
    setTimeUPPopupAlreadyOpened(false)
  }, [router.pathname])

  const scrollEventListener = useCallback(
    (e) => {
      const { scrollHeight, scrollTop } = e.target.documentElement
      if (
        !scrollPopupAlreadyOpened &&
        !timeUPPopupAlreadyOpened &&
        !router.pathname.startsWith('/auth') &&
        router.pathname !== '404' &&
        router.pathname !== '500' &&
        scrollTop / scrollHeight > 0.7
      ) {
        setScrollPopupOpen(true)
        setScrollPopupAlreadyOpened(true)
        setEvent(PlausibleEvent.PopupDisplayed, {
          referrer,
          popupType: PopupType.OnDownScroll,
          locale: router.locale,
          domain: window.location.host,
        })
      }

      setShowScroll(router.pathname.startsWith('/auth') ? false : scrollTop > 0)
    },
    [
      setShowScroll,
      setScrollPopupOpen,
      timeUPPopupOpen,
      scrollPopupAlreadyOpened,
      router.pathname,
      timeUPPopupAlreadyOpened,
    ],
  )

  const scrollToTop = useCallback(() => {
    setEvent(PlausibleEvent.ClickedScrollUp, { referrer })
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollEventListener)
    return () => window.removeEventListener('scroll', scrollEventListener)
  }, [scrollEventListener])

  useEffect(() => {
    if (
      !router.pathname.startsWith('/auth') &&
      !timeUPPopupOpen &&
      !scrollPopupAlreadyOpened &&
      router.pathname !== '404' &&
      router.pathname !== '500'
    ) {
      tick.current = setInterval(() => {
        setTimer(timer + 1)
        if (timer >= 120 && !scrollPopupOpen) {
          setTimeUPPopupOpen(true)
          setTimeUPPopupAlreadyOpened(true)
          setEvent(PlausibleEvent.PopupDisplayed, {
            popupType: PopupType.OnTimeSpacing,
            referrer,
            locale: router.locale,
            domain: window.location.host,
            IP: router.locale,
          })
        }
      }, 1000)
    } else {
      clearInterval(tick.current)
      setTimer(0)
    }

    return () => clearInterval(tick.current)
  }, [
    timer,
    timeUPPopupOpen,
    scrollPopupOpen,
    router.pathname,
    setTimeUPPopupOpen,
    setTimeUPPopupAlreadyOpened,
    scrollPopupAlreadyOpened,
  ])

  useEffect(() => {
    localStorage.setItem('landingLanguagesList', JSON.stringify(languagesWhitelist))
  }, [])

  useEffect(() => {
    function redirectToDashBoard() {
      const tokenAvailable = Boolean(localStorage.getItem('accessToken'))
      if (tokenAvailable) {
        window.location.assign(process.env.NEXT_PUBLIC_REACT_DASHBOARD_LINK)
      }
    }
    window.addEventListener('storage', redirectToDashBoard)

    return () => window.removeEventListener('storage', redirectToDashBoard)
  }, [])

  return (
    <>
      {!redirectToApp && (
        <>
          <Head>
            {hostname && (
              <>
                <link rel="alternate" hrefLang="en-IN" href={`https://binany.com${path}`} />
                <link rel="alternate" hrefLang="hi-IN" href={`https://binany.com/hi${path}`} />
                <link rel="alternate" hrefLang="bn-IN" href={`https://binany.com/bn${path}`} />
                <link
                  rel="alternate"
                  hrefLang="en-BD"
                  href={`https://${process.env.NEXT_PUBLIC_BN_HOSTNAME}/en-BD${path}`}
                />
                <link
                  rel="alternate"
                  hrefLang="bn-BD"
                  href={`https://${process.env.NEXT_PUBLIC_BN_HOSTNAME}${path}`}
                />
                <link
                  rel="alternate"
                  hrefLang="uz-UZ"
                  href={`https://${process.env.NEXT_PUBLIC_UZ_HOSTNAME}${path}`}
                />
                <link
                  rel="alternate"
                  hrefLang="ru-UZ"
                  href={`https://${process.env.NEXT_PUBLIC_UZ_HOSTNAME}/ru${path}`}
                />
                <link rel="alternate" hrefLang="ms-MY" href={`https://binany.com/ms${path}`} />
                <link rel="alternate" hrefLang="id-ID" href={`https://binany.com/id${path}`} />
                <link rel="alternate" hrefLang="ur-PK" href={`https://binany.com/ur${path}`} />
                <link rel="alternate" hrefLang="my-MM" href={`https://binany.com/my${path}`} />
                <link rel="alternate" hrefLang="en" href={`https://binany.com${path}`} />
                <link
                  rel="alternate"
                  hrefLang="en"
                  href={`https://${process.env.NEXT_PUBLIC_BN_HOSTNAME}/en-BD${path}`}
                />
                <link rel="alternate" hrefLang="hi" href={`https://binany.com/hi${path}`} />

                <link rel="alternate" hrefLang="bn" href={`https://binany.com/bn${path}`} />

                <link
                  rel="alternate"
                  hrefLang="uz"
                  href={`https://${process.env.NEXT_PUBLIC_UZ_HOSTNAME}${path}`}
                />

                <link
                  rel="alternate"
                  hrefLang="ru"
                  href={`https://${process.env.NEXT_PUBLIC_UZ_HOSTNAME}/ru${path}`}
                />
                <link rel="alternate" hrefLang="ms" href={`https://binany.com/ms${path}`} />
                <link rel="alternate" hrefLang="id" href={`https://binany.com/id${path}`} />
                <link rel="alternate" hrefLang="ur" href={`https://binany.com/ur${path}`} />
                <link rel="alternate" hrefLang="my" href={`https://binany.com/my${path}`} />
              </>
            )}
            {hostname && <link rel="canonical" href={cannonicalRef()} />}

            <link
              rel="apple-touch-icon"
              sizes="57x57"
              href="/images/favicon/apple-icon-57x57.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="60x60"
              href="/images/favicon/apple-icon-60x60.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="72x72"
              href="/images/favicon/apple-icon-72x72.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href="/images/favicon/apple-icon-76x76.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="114x114"
              href="/images/favicon/apple-icon-114x114.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href="/images/favicon/apple-icon-120x120.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="144x144"
              href="/images/favicon/apple-icon-144x144.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/images/favicon/apple-icon-152x152.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/images/favicon/apple-icon-180x180.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/images/favicon/android-icon-192x192.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/images/favicon/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href="/images/favicon/favicon-96x96.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/images/favicon/favicon-16x16.png"
            />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
          </Head>

          <Script
            strategy="afterInteractive"
            id="gtm-script"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',"${process.env.NEXT_PUBLIC_REACT_GTM_ID}");`,
            }}
          />
          <noscript>
            <iframe
              title="Google Tag Manager"
              src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_REACT_GTM_ID}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
          <Script
            src="//web.webpushs.com/js/push/d8c455ba8312ea603b3295eeb9c25769_1.js"
            strategy="lazyOnload"
            async
          />
          <Script src="https://connect.facebook.net/en_US/sdk.js" strategy="lazyOnload" async />

          <Script
            strategy="afterInteractive"
            id="tg-mini-app"
            src="https://telegram.org/js/telegram-web-app.js"
          />
          <LanguageProvider>
            <InfoProvider>
              <Component host={hostname} referrer={referrer} {...pageProps} />

              <Popup
                t={t}
                opened={scrollPopupOpen}
                setOpen={setScrollPopupOpen}
                popupType={PopupType.OnDownScroll}
                referrer={referrer}
              />
              <Popup
                t={t}
                opened={timeUPPopupOpen}
                setOpen={setTimeUPPopupOpen}
                popupType={PopupType.OnTimeSpacing}
                referrer={referrer}
              />
            </InfoProvider>
          </LanguageProvider>

          <button
            onClick={scrollToTop}
            className={cn(styles.scrollButton, { [styles.shown]: showScroll })}
            id="common-app-scroll_up-button"
          >
            <div className={styles.imageWrapper}>
              <Image fill priority src="/images/util/scrollup.svg" alt="scrollup" />
            </div>
          </button>

          <ToastContainer />
        </>
      )}
    </>
  )
}

export default appWithTranslation(MyApp)
