import * as amplitude from '@amplitude/analytics-browser'
import { useCallback } from 'react'

const amplitudeApiKey = process.env.NEXT_PUBLIC_AMPLITUDE

const useAmplitude = () => {
  const initAmplitude = useCallback(() => {
    if (amplitudeApiKey) {
      amplitude?.init(amplitudeApiKey, {
        defaultTracking: { formInteractions: false },
        serverUrl: process.env.NEXT_PUBLIC_AMPLITUDE_PROXY_URL,
      })
    }
  }, [])

  const track = useCallback(
    (
      eventInput: string | amplitude.Types.BaseEvent,
      eventProperties?: Record<string, any>,
      eventOptions?: amplitude.Types.EventOptions,
    ) => {
      if (amplitudeApiKey) {
        amplitude?.track(eventInput, eventProperties, eventOptions)
      }
    },
    [],
  )

  return {
    initAmplitude,
    track,
  }
}

export default useAmplitude
