import cn from 'classnames'
import { PlausibleEvent } from 'enums/plausible'
import { useCallback, useEffect } from 'react'
import usePlausible from 'utils/hooks/usePlausible'

import Image from 'next/image'
import { useRouter } from 'next/router'

import Button from 'components/Button'
import Typography from 'components/Typography'

import styles from './Popup.module.scss'

import RegisterNowImage from '../../../public/images/newPictures/register-now-image.png'

const Popup = ({ t, opened, setOpen, popupType, referrer }) => {
  const router = useRouter()

  const setEvent = usePlausible()

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [opened])

  const onClose = useCallback(() => {
    setEvent(PlausibleEvent.ClosePopup, {
      popupType,
      referrer,
      locale: router.locale,
      domain: window.location.host,
    })
    setOpen(false)
  }, [])

  const onRegister = useCallback(() => {
    setEvent(PlausibleEvent.ClickOnPopup, {
      popupType,
      referrer,
      locale: router.locale,
      domain: window.location.host,
    })
    localStorage.setItem('registeringFromPopup', popupType)
    setOpen(false)
  }, [router])

  return (
    <div className={cn(styles.container, { [styles.opened]: opened })}>
      <div className={styles.popupOverlay} onClick={onClose} />
      <div className={styles.popup}>
        <button className={styles.closeBtn} onClick={onClose} id="common-modal-close-button">
          <Image src="/images/times.svg" alt="close" fill />
        </button>

        <Image
          src={RegisterNowImage}
          alt="register now image"
          loading="lazy"
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: 252,
            objectFit: 'contain',
            alignSelf: 'center',
          }}
        />

        <div className={styles.content}>
          <div className={styles.header}>
            <Typography variant="h4" className={styles.title}>
              {t('main:popupRegisterRightNow')}
            </Typography>
            <div className={styles.textContainer}>
              <div className={styles.textWithIcon}>
                <div className={styles.icon}>
                  <Image src="/images/gift.svg" alt="dashboard icon" width={20} height={20} />
                </div>
                <Typography variant="pMain" className={styles.subtitle}>
                  {t('main:popupBonusGaranteed')}
                </Typography>
              </div>
              <div className={styles.textWithIcon}>
                <div className={styles.icon}>
                  <Image src="/images/dashboard.svg" alt="dashboard icon" width={24} height={24} />
                </div>
                <Typography variant="pMain" className={styles.subtitle}>
                  {t('main:popupGetAccess')}
                </Typography>
              </div>
            </div>
          </div>

          <Button
            href={`/auth/signup?from=popup:${popupType}`}
            as="/auth/signup"
            className={styles.registerBtn}
            onClick={onRegister}
            id="common-modal-register-button"
          >
            {t('main:popupGetBonus')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Popup
